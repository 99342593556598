import { useState } from 'react'
import axios from 'axios'

interface UseVerifyPayment {
  isLoading: boolean
  errorMessage: string
  verifyPayment: (paymentId: string) => Promise<{ message: string } | null>
}
export const useVerifyPayment = (): UseVerifyPayment => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const verifyPayment = async (paymentId: string): Promise<{ message: string } | null> => {
    setIsLoading(true)
    setErrorMessage('')

    try {
      const result = (
        await axios.get(
          `https://server-app-zez6odzcrq-rj.a.run.app/pao-camilo-wedding/${paymentId}`
        )
      ).data
      setIsLoading(false)
      return result
    } catch (error) {
      setErrorMessage('Ha ocurrido un error al tratar de obtener información sobre el pago.')
      setIsLoading(false)
      return null
    }
  }

  return {
    isLoading,
    errorMessage,
    verifyPayment
  }
}
