import { type FunctionComponent } from 'react'
import Section from '../components/Section'
import myImg1 from '../static/IMG_0316.jpeg'
import myImg2 from '../static/IMG_0012.jpeg'
import myImg3 from '../static/IMG_2232.jpeg'
import myImg4 from '../static/IMG_2798.jpeg'
import myImg5 from '../static/IMG_1139.jpeg'
import myImg6 from '../static/IMG_5373.jpeg'
import myImg7 from '../static/IMG_4585.jpeg'
import myImg8 from '../static/IMG_5704.jpeg'
import myImg9 from '../static/IMG_8821.jpeg'
import myImg10 from '../static/IMG_9684.jpeg'
import myImg11 from '../static/IMG_7136.jpeg'
import myImg12 from '../static/IMG_8125.jpeg'
import myImg13 from '../static/IMG_5898.jpeg'

import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { useMediaQuery, useTheme } from '@mui/material'

const itemData = [
  {
    img: myImg1,
    title: 'Tren',
    rows: 2,
    cols: 2
  },
  {
    img: myImg2,
    title: 'Arbol'
  },
  {
    img: myImg3,
    title: 'Nieve'
  },
  {
    img: myImg4,
    title: 'Fonda'
  },
  {
    img: myImg5,
    title: 'Argentina'
  },
  {
    img: myImg6,
    title: 'San Andres',
    cols: 2
  },
  {
    img: myImg7,
    title: 'Cerveza',
    rows: 2,
    cols: 2
  },
  {
    img: myImg8,
    title: 'Rio'
  },
  {
    img: myImg9,
    title: 'Bar'
  },
  {
    img: myImg10,
    title: 'Palacio',
    rows: 2,
    cols: 2
  },
  {
    img: myImg11,
    title: 'Geyser'
  },
  {
    img: myImg12,
    title: 'Bodega'
  },
  {
    img: myImg13,
    title: 'Mirador',
    cols: 2
  }
]

const Images: FunctionComponent = () => {
  const theme = useTheme()
  const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Section>
      <ImageList
        sx={{ width: '100%', mt: 0 }}
        variant="quilted"
        cols={greaterThanMid ? 8 : 4}
        >
        {itemData.map((item) => (
          <ImageListItem key={item.img} cols={item.cols ?? 1} rows={item.rows ?? 1}>
            <img src={item.img} alt={item.title} loading="lazy" />
          </ImageListItem>
        ))}
      </ImageList>
    </Section>
  )
}

export default Images
