import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import { useState, type FunctionComponent } from 'react'
import { HomeRounded, PlaylistAddCheckRounded, LocationOn, RedeemOutlined, DryCleaning } from '@mui/icons-material'
import { logEvent } from '../services/analytics'

interface Props {
  onNavigate: (indexOfElement: number) => void
}

const events = [
  'navigation_counter',
  'navigation_confirm',
  'navigation_location',
  'navigation_gifts',
  'navigation_dress_code',
  'navigation_table'
]

const Navigation: FunctionComponent<Props> = ({ onNavigate }) => {
  const [currentNavElementIndex, setCurrentNavElementIndex] = useState(0)

  return (
    <BottomNavigation
      value={currentNavElementIndex}
      onChange={(event, value: number) => {
        logEvent(events[value])
        setCurrentNavElementIndex(value)
        onNavigate(value)
      }}
      showLabels
      sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1000
      }} >
      <BottomNavigationAction label="Inicio" icon={<HomeRounded />} />
      <BottomNavigationAction label="Confirma" icon={<PlaylistAddCheckRounded />} />
      <BottomNavigationAction label="Ubicación" icon={<LocationOn />} />
      <BottomNavigationAction label="Regalo" icon={<RedeemOutlined />} />
      <BottomNavigationAction label="Dress code" icon={<DryCleaning />} />
      {/* <BottomNavigationAction sx={{ mr: 2 }} label="Tu mesa" icon={<LocalDining />} /> */}
    </BottomNavigation>
  )
}

export default Navigation
