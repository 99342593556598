import { type FunctionComponent, useRef } from 'react'
import './App.css'
import Counter from './views/Counter'
import Location from './views/Location'
import Confirm from './views/Confirm'
import Gifts from './views/Gifts'
import Images from './views/Images'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import VerifyPayment from './views/VerifyPayment'
import Navigation from './components/Navigation'
import DressCode from './views/DressCode'

const App: FunctionComponent = () => {
  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null)
  ]

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        (
          <>
            <Navigation onNavigate={(indexOfElement) => {
              sectionRefs[indexOfElement].current?.scrollIntoView({ behavior: 'smooth' })
            }} />
            <div ref={sectionRefs[0]}><Counter /></div>
            <div ref={sectionRefs[1]}><Confirm /></div>
            <div ref={sectionRefs[2]}><Location /></div>
            <div ref={sectionRefs[3]}><Gifts /></div>
            <div ref={sectionRefs[4]}><DressCode /></div>
            <Images />
            {/* <div ref={sectionRefs[5]}><Table /></div>
            <Typography align="center" variant="h5" sx={{ mt: 8, mb: 8, backgroundColor: '#dcdfe0' }}>
              Estamos muy felices de compartir con ustedes este día ¡GRACIAS!
            </Typography> */}
          </>
        )
      )
    },
    { path: '/payment/:paymentId', element: <VerifyPayment /> }
  ])

  return <RouterProvider router={router} />
}

export default App
