import { initializeApp } from 'firebase/app'
import { getFirestore, collection } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyBzsQCL7QhBxY-8Fa-ntviS6LpEgqkzF-k',
  authDomain: 'wedding-pao-camilo.firebaseapp.com',
  projectId: 'wedding-pao-camilo',
  storageBucket: 'wedding-pao-camilo.appspot.com',
  messagingSenderId: '510255623839',
  appId: '1:510255623839:web:c62606dfe32c0012483caa',
  measurementId: 'G-S78DSQM6YH'
}

export const app = initializeApp(firebaseConfig)
export const firestore = getFirestore(app)
export const analytics = getAnalytics(app)

export const guestsCollection = collection(firestore, 'guests')
export const giftsCollection = collection(firestore, 'gifts')
