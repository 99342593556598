import { styled } from '@mui/material/styles'

const Section = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  display: 'flex',
  alignItems: 'center'
  // [theme.breakpoints.up('xs')]: {
  //   height: '80vh',
  //   minHeight: 500
  // }
}))

export default Section
