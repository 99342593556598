import { CircularProgress, Container, Typography } from '@mui/material'
import { useEffect, useState, type FunctionComponent } from 'react'
import Section from '../components/Section'
import { type Gift } from '../models/gift.model'
import { useGetGifts } from '../hooks/use.get.gifts'
import TriggerPaymentForm from '../components/gifts/TriggerPaymentForm'
import { logEvent } from '../services/analytics'

const Gifts: FunctionComponent = () => {
  const [gifts, setGifts] = useState<Gift[]>([])
  const { isLoading, getGifts } = useGetGifts()

  const fetchGifts = async (): Promise<void> => {
    logEvent('get_gifts')
    setGifts(await getGifts())
  }

  useEffect(() => {
    fetchGifts()
  }, [])

  return (
    <Section sx={{ color: 'black' }}>
      <Container sx={{
        mt: 10,
        mb: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Typography align="center" variant="h4" sx={{ mb: 3 }}>
          Hacer un regalo
        </Typography>
        {isLoading ? <CircularProgress /> : <TriggerPaymentForm gifts={gifts} />}
      </Container>
    </Section>
  )
}

export default Gifts
