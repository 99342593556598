import { type FunctionComponent } from 'react'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useUpdateGuest } from '../../../hooks/use.update.guest'
import { LoadingButton } from '@mui/lab'
import TransitionAlert from '../../TransitionAlert'
import { type Guest } from '../../../models/guest.model'
import { type UpdateData } from 'firebase/firestore'

interface Props {
  guest: Guest
  onSuccess: (message: string) => void
}
const ConfirmFamilyForm: FunctionComponent<Props> = ({ guest, onSuccess }) => {
  const { isLoading, errorMessage, successMessage, updateGuest } = useUpdateGuest()
  const formik = useFormik({
    initialValues: { guestsMap: guest.guestsMap, comment: '' },
    onSubmit: async (values) => {
      const updateData: UpdateData<Guest> = {
        code: guest.code,
        ...values
      }
      await updateGuest(guest.id, { ...updateData })
      onSuccess(successMessage)
    }
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>

      <FormControl fullWidth sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormLabel component="legend">
          Selecciona los nombres de quienes irán al evento
        </FormLabel>
        <FormGroup>
          {Object.keys(guest.guestsMap).map(x => (
            <FormControlLabel
              key={x}
              control={
                <Checkbox
                  checked={formik.values.guestsMap[x]}
                  {...formik.getFieldProps(`guestsMap.${x}`)} />
              }
              label={x} />
          ))}
        </FormGroup>
      </FormControl>

      {Object.values(formik.values.guestsMap).some(x => x) && (
        <TextField
          label="Observaciones (alergias u otros)"
          size="small"
          fullWidth
          {...formik.getFieldProps('comment')}
          error={formik.touched.comment && Boolean(formik.errors.comment)}
          helperText={formik.touched.comment && formik.errors.comment} />
      )}

      <LoadingButton
        loading={isLoading}
        sx={{ mt: 1 }}
        size="small"
        type="submit"
        variant="contained">
        Enviar
      </LoadingButton>

      <TransitionAlert open={Boolean(errorMessage)} sx={{ mt: 1 }}>
        {errorMessage}
      </TransitionAlert>
    </form>
  )
}

export default ConfirmFamilyForm
