import { useEffect, useState, type FunctionComponent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useVerifyPayment } from '../hooks/use.verify.payment'
import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container, Typography } from '@mui/material'
import Section from '../components/Section'
import TransitionAlert from '../components/TransitionAlert'

const VerifyPayment: FunctionComponent = () => {
  const { paymentId } = useParams()
  const { isLoading, errorMessage, verifyPayment } = useVerifyPayment()
  const [paymentStatus, setPaymentStatus] = useState('')
  const navigate = useNavigate()

  const fetchData = async (): Promise<void> => {
    const result = await verifyPayment(paymentId as string)
    if (!result) {
      return
    }
    setPaymentStatus(result.message)
  }

  useEffect(() => { fetchData() }, [])

  const statusMap: Record<string, string> = {
    PENDING: 'Este pago todavía se encuentra pendiente y no ha sido confirmado por el medio de pago.',
    PAID: 'Este pago se ha realizado correctamente. !Muchas gracias!',
    REJECTED: 'Este pago ha sido rechazado por el medio de pago.',
    CANCELED: 'Este pago ha sido cancelado.'
  }

  return (
    <Section sx={{ height: '100vh' }}>
      <Container
        sx={{
          mt: 3,
          mb: 14,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Box sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: 'black',
          opacity: 0.3,
          zIndex: -1
        }}/>
        <Box sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          zIndex: -2,
          backgroundImage: 'url(https://media.istockphoto.com/id/528482024/es/foto/una-mesa-decorada-con-flores.jpg?s=1024x1024&w=is&k=20&c=2V8bd9Cl9BDT0sQ_e5fZFIQXEwSt_zzZurpjRcNzoEU=)',
          filter: 'blur(5px)'
        }}/>
        <Card>
          <CardHeader title="Verificación del pago"/>
          <CardContent>
            <center>
              {isLoading
                ? <CircularProgress/>
                : (
                    errorMessage
                      ? <TransitionAlert>{errorMessage}</TransitionAlert>
                      : (
                        <Typography variant="h6" align="center">
                          {statusMap[paymentStatus]}
                        </Typography>
                        )
                  )}
            </center>
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={() => { navigate('/') }}>
              Volver a la página principal
            </Button>
            {errorMessage && (
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={() => { fetchData() }} >
                Intentar de nuevo
              </Button>
            )}
          </CardActions>
        </Card>
      </Container>
    </Section>
  )
}

export default VerifyPayment
