import { Alert, Box, Collapse, IconButton, type SxProps } from '@mui/material'
import { type FunctionComponent, type ReactNode } from 'react'
import type { AlertColor } from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  children: ReactNode
  onClose?: () => void
  severity?: AlertColor
  open?: boolean
  sx?: SxProps
}
const TransitionAlert: FunctionComponent<Props> = ({ children, onClose, severity = 'error', open = true, sx = {} }) => (
  <Box sx={{ ...sx, width: '100%' }}>
    <Collapse in={open}>
      <Alert
        severity={severity}
        action={
          !!onClose &&
            <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {children}
      </Alert>
    </Collapse>
  </Box>
)

export default TransitionAlert
