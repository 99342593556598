import { Box, Container, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useRef, useState, type FunctionComponent } from 'react'
import Section from '../components/Section'
import Background from '../components/Background'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

import party from '../static/party.png'
import blueDress from '../static/00071-638141040.png'
import tealDress from '../static/00010-1567780349.png'
import redLongDress from '../static/00017-4191258871.png'
import darkBlueDress from '../static/00026-2134205730.png'
import blackDress from '../static/00029-2134205733.png'
import bicolorBowTie from '../static/00043-2686371501.png'
import lightGreenSuit from '../static/00044-2686371502.png'
import contrastSuit from '../static/00049-363457109.png'
import serialKiller from '../static/00011-2520823442.png'
import bicolorSuit from '../static/00057-363457117.png'
import redCouple from '../static/00081-4243131949.png'
import roseCouple from '../static/00148-17230401.png'
import tealCouple from '../static/00094-3612108701.png'
import purpleCouple from '../static/00098-4010213583.png'
import matureCouple from '../static/00101-1026252080.png'

const DressCode: FunctionComponent = () => {
  const theme = useTheme()
  const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'))

  const images = [
    redLongDress,
    darkBlueDress,
    tealDress,
    blackDress,
    blueDress,
    bicolorBowTie,
    lightGreenSuit,
    contrastSuit,
    serialKiller,
    bicolorSuit,
    roseCouple,
    matureCouple,
    redCouple,
    purpleCouple,
    tealCouple
  ]

  const [imageIndex, setImageIndex] = useState(0)
  const imageContainerRef = useRef<HTMLElement>(null)

  return (
    <Section>
      <Container
        sx={{
          my: 4,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Background
          opacity={0.5}
          backgroundImage={`url(${party})`}
          blur={8} />
        <Typography align="center" variant="h3" sx={{ mt: 3, fontWeight: 700 }}>
          Dress code
        </Typography>
        <Typography align="center" variant="h5" sx={{ mt: 2, mb: 1, fontWeight: 600 }}>
          Creative Black Tie
        </Typography>
        <Typography align="justify" variant={greaterThanMid ? 'h6' : 'body1'} sx={{ mb: 2, textIndent: '2em' }}>
          En español {'"Etiqueta creativa"'}, combina vestimenta <strong>formal y elegante con un toque creativo. </strong>
          Permite mezclar texturas, colores y cortes, además de innovar con complementos.
          Se podría considerar como un outfit formal, pero con detalles llamativos.
          Básicamente, se trata de una vestimenta de etiqueta que admite colores y vestidos cortos.<br/>
        </Typography>
        <Typography align="justify" variant={greaterThanMid ? 'h6' : 'body1'} sx={{ mb: 2, textIndent: '2em' }}>
          Para los hombres, se admiten elementos como solapa contrastada, zapatos de punta alada, corbatín o corbata con dibujos.
          Las mujeres pueden usar vestidos cortos o trajes coloridos.
          La intención es mostrar estilo personal. Estas descripciones son sugerencias, no requerimientos.
          Cada uno debe usar su propio criterio.
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={11} md={5}>
            <Box sx={{ position: 'relative', overflow: 'hidden' }} ref={imageContainerRef}>
              {imageIndex !== 0 && <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: '45%',
                  left: 4,
                  backgroundColor: 'white',
                  color: 'black',
                  opacity: 0.8,
                  zIndex: 3,
                  '&:hover': { backgroundColor: 'white', opacity: 0.5 }
                }}
                onClick={() => { setImageIndex(imageIndex - 1) }}>
                <ChevronLeft/>
              </IconButton>}
              {
                images.map((x, i) => (
                  <Box
                    key={`image-${i}`}
                    component="img"
                    sx={{
                      display: imageIndex === i ? 'inline' : 'none',
                      objectFit: 'contain',
                      width: '100%',
                      borderRadius: '3%'
                    }}
                    src={x} />
                ))
              }
              {(imageIndex !== images.length - 1) && <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: '45%',
                  right: 4,
                  backgroundColor: 'white',
                  color: 'black',
                  opacity: 0.8,
                  '&:hover': { backgroundColor: 'white', opacity: 0.5 }
                }}
                onClick={() => { setImageIndex(imageIndex + 1) }}>
                <ChevronRight/>
              </IconButton>}
            </Box>
          </Grid>

        </Grid>
      </Container>
    </Section>
  )
}

export default DressCode
