import { useState } from 'react'
import { type FlowCreatePaymentResponse } from '../models/flow.model'
import axios from 'axios'
import { logEvent } from '../services/analytics'

interface UseTriggerPayment {
  isLoading: boolean
  errorMessage: string
  triggerPayment: (
    params: {
      email: string
      comment: string
      address: string
      name: string
      giftId: string
    }
  ) => Promise<FlowCreatePaymentResponse | null>
}
export const useTriggerPayment = (): UseTriggerPayment => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const triggerPayment = async (params: {
    email: string
    comment: string
    address: string
    name: string
    giftId: string
  }): Promise<FlowCreatePaymentResponse | null> => {
    logEvent('trigger_payment', params)
    setIsLoading(true)
    setErrorMessage('')

    try {
      const result = (await axios.post('https://server-app-zez6odzcrq-rj.a.run.app/pao-camilo-wedding/trigger-payment', params)).data
      setIsLoading(false)
      return result
    } catch (error) {
      setErrorMessage('Ha ocurrido un error al tratar de comenzar el pago.')
      setIsLoading(false)
      return null
    }
  }

  return {
    isLoading,
    errorMessage,
    triggerPayment
  }
}
