import { Box, Card, CardActions, CardContent, CardHeader, CardMedia, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useEffect, useState, type FunctionComponent } from 'react'
import { type Gift } from '../../models/gift.model'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useTriggerPayment } from '../../hooks/use.trigger.payment'
import { logEvent } from '../../services/analytics'

interface Props {
  gifts: Gift[]
}
const TriggerPaymentForm: FunctionComponent<Props> = ({ gifts }) => {
  const [selectedGift, setSelectedGift] = useState<Gift | null>(null)
  const { isLoading, triggerPayment } = useTriggerPayment()

  const formik = useFormik({
    initialValues: { giftId: '', name: '', comment: '', address: '', email: '' },
    validationSchema: yup.object({
      giftId: yup.string().required('Debe seleccionar una opción'),
      name: yup.string().required('Debe ingresar nombre de quién hace el regalo'),
      comment: yup.string().optional(),
      email: yup.string().required('Debe ingresar e-mail (para comprobante)').email('Debe ser un e-mail'),
      address: yup.string().required('Debe ingresar dirección (para tarjeta de agradecimiento)')
    }),
    onSubmit: async (values): Promise<void> => {
      const result = await triggerPayment(values)
      if (!result) {
        return
      }
      window.location.href = `${result?.url}?token=${result?.token}`
    }
  })

  useEffect(() => {
    const gift = gifts.find(x => x.id === formik.values.giftId) ?? null
    logEvent('select_gift', gift ?? {})
    setSelectedGift(gift)
  }, [formik.values.giftId])
  return (
    <Box
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
      sx={{ width: { xs: '95%', md: '50%' } }}>
      <FormControl
        disabled={isLoading}
        size="small"
        fullWidth
        error={formik.touched.giftId && Boolean(formik.errors.giftId)}>
        <InputLabel>Lista de regalos</InputLabel>
        <Select label="Lista de regalos" {...formik.getFieldProps('giftId')}>
          {gifts.map(x => (
            <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
          ))}
        </Select>
        {formik.touched.giftId && Boolean(formik.errors.giftId) && (
          <FormHelperText>{formik.errors.giftId}</FormHelperText>
        )}
      </FormControl>
      {formik.values.giftId && (
        <Card sx={{ my: 1 }}>
          <CardHeader
            title={
                    `$ ${(new Intl.NumberFormat('EU')).format(selectedGift?.amount as number)}`
                  }/>
          <center>
            <CardMedia
              sx={{ maxHeight: '300px', maxWidth: '100%', objectFit: 'contain', p: 1 }}
              component="img"
              image={selectedGift?.imageUrl} />
          </center>
          <CardContent>
            <Typography>
              {selectedGift?.description}
            </Typography>
            <TextField
              disabled={isLoading}
              sx={{ my: 1 }}
              size="small"
              fullWidth
              label="Nombre de quien envía el regalo"
              {...formik.getFieldProps('name')}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name} />
            <TextField
              disabled={isLoading}
              sx={{ mb: 1 }}
              size="small"
              fullWidth
              label="E-mail"
              {...formik.getFieldProps('email')}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email} />
            <TextField
              disabled={isLoading}
              sx={{ mb: 1 }}
              size="small"
              fullWidth
              label="Dirección"
              {...formik.getFieldProps('address')}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address} />
            <TextField
              disabled={isLoading}
              multiline
              rows={4}
              size="small"
              fullWidth
              label="Dedicatoria"
              {...formik.getFieldProps('comment')}
              error={formik.touched.comment && Boolean(formik.errors.comment)}
              helperText={formik.touched.comment && formik.errors.comment} />
          </CardContent>
          <CardActions>
            <LoadingButton loading={isLoading} size="small" type="submit" variant="contained">
              Continuar
            </LoadingButton>
          </CardActions>
        </Card>
      )}
    </Box>
  )
}
export default TriggerPaymentForm
