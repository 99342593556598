import { useState } from 'react'
import { doc, serverTimestamp, type UpdateData, updateDoc } from 'firebase/firestore'
import { guestsCollection } from '../services/firebase'
import { type Guest } from '../models/guest.model'
import { logEvent } from '../services/analytics'

interface UseUpdateGuest {
  isLoading: boolean
  errorMessage: string
  successMessage: string
  updateGuest: (guestId: string, data: UpdateData<Guest>) => Promise<void>
}

export const useUpdateGuest = (): UseUpdateGuest => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const updateGuest = async (guestId: string, data: UpdateData<Guest>): Promise<void> => {
    logEvent('update_guest', { guestId, ...data })
    setIsLoading(true)
    setErrorMessage('')
    setSuccessMessage('')

    const docRef = doc(guestsCollection, guestId)

    try {
      await updateDoc(docRef, { ...data, lastUpdatedAt: serverTimestamp() })
      setSuccessMessage('Se ha guardado tu respuesta con éxito!')
    } catch (error) {
      setErrorMessage('No se pudo actualizar registro')
    }
    setIsLoading(false)
  }

  return {
    isLoading,
    errorMessage,
    successMessage,
    updateGuest
  }
}
