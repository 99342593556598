import { Container } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { type FunctionComponent } from 'react'
import useCountDown from '../hooks/use.count.down'
import DateNumberDisplay from '../components/counter/DateNumberDisplay'
import Section from '../components/Section'
import Avatar from '@mui/material/Avatar'
import Paper from '@mui/material/Paper'

import myImg1 from '../static/IMG_0316.jpeg'
import Background from '../components/Background'
import { logEvent } from '../services/analytics'

const Counter: FunctionComponent = () => {
  const [days, hours, minutes, seconds] = useCountDown(new Date('2025-02-22T20:30:00.000Z'))
  logEvent('use_countdown')
  return (
    <Section>
      <Container
        sx={{
          mt: 3,
          mb: 14,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Background
          opacity={0.3}
          backgroundImage="url(https://media.istockphoto.com/id/1455769561/es/foto/anillos-elegantes-flores-sobre-fondo-de-mesa-de-madera-cartas-de-los-novios-votos-compromiso.jpg?s=1024x1024&w=is&k=20&c=ONIWbWaG9YJwj8Q0mjzrdIAam5wQIW0VruEjiU0oThY=)" />
        <Typography
          color="inherit"
          align="center"
          variant="h3"
          sx={{ mt: 5, mb: 3, fontFamily: 'Raleway' }}>
          Paolangela y Camilo
        </Typography>
        <Avatar alt="Extra" src={myImg1} sx={{ width: { xs: 150, md: 200 }, height: { xs: 150, md: 200 } }} />
        <Grid sx={{ my: 2, mx: 3 }} container spacing={1}>
          <Grid item sx={{ display: { xs: 'none', md: 'grid' } }} md={2} />
          <DateNumberDisplay text="Días" value={days} />
          <DateNumberDisplay text="Horas" value={hours} />
          <DateNumberDisplay text="Minutos" value={minutes} />
          <DateNumberDisplay text="Segundos" value={seconds} />
        </Grid>
        <Typography variant="h4" align="center" sx={{ mt: 2, fontWeight: 700 }}>
          ¡Nos vamos a casar!
        </Typography>
        <Typography variant="h5" align="center" sx={{ fontWeight: 600, mb: 3 }}>
          Sábado 22 Febrero 2025
        </Typography>
        <Paper elevation={5} sx={{ backgroundColor: 'transparent', width: '70%', color: 'inherit' }}>
          <Typography variant="h5" align="center" sx={{ mt: 3 }}>
            Ceremonia 17:30 Hrs.
          </Typography>
          <Typography variant="h5" align="center" sx={{ mt: 1, mb: 3 }}>
            Recepción 19:30 Hrs.
          </Typography>
        </Paper>
      </Container>
    </Section>
  )
}

export default Counter
