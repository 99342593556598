import { useState } from 'react'
import { type Gift } from '../models/gift.model'
import { getDocs, orderBy, query } from 'firebase/firestore'
import { giftsCollection } from '../services/firebase'

interface UseGetGifts {
  isLoading: boolean
  getGifts: () => Promise<Gift[]>
}
export const useGetGifts = (): UseGetGifts => {
  const [isLoading, setIsLoading] = useState(false)

  const getGifts = async (): Promise<Gift[]> => {
    setIsLoading(true)

    const q = query(giftsCollection, orderBy('amount'))
    const querySnapshot = await getDocs(q)

    return await new Promise((resolve) => {
      setIsLoading(false)

      if (querySnapshot.empty) {
        resolve([])
        return
      }

      resolve(querySnapshot.docs.map(x => ({ id: x.id, ...x.data() } as Gift)))
    })
  }

  return {
    isLoading,
    getGifts
  }
}
