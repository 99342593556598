import { useState } from 'react'
import { guestsCollection } from '../services/firebase'
import { getDocs, query, where } from 'firebase/firestore'
import { type Guest } from '../models/guest.model'

interface UseGetGuest {
  isLoading: boolean
  errorMessage: string
  getGuest: (code: string) => Promise<Guest | null>
}

export const useGetGuest = (): UseGetGuest => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const getGuest = async (codeIn: string): Promise<Guest | null> => {
    const code = codeIn.toLowerCase()
    setIsLoading(true)
    setErrorMessage('')

    const q = query(guestsCollection, where('code', '==', code))
    const querySnapshot = await getDocs(q)

    return await new Promise((resolve) => {
      setIsLoading(false)

      if (querySnapshot.empty) {
        setErrorMessage(`El código ${code} no está registrado`)
        resolve(null)
        return
      }

      const [doc] = querySnapshot.docs
      resolve({ id: doc.id, ...doc.data() } as Guest)
    })
  }

  return {
    isLoading,
    errorMessage,
    getGuest
  }
}
