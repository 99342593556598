import { type FunctionComponent } from 'react'
import { GuestType, type Guest } from '../../models/guest.model'
import { Grid, TextField } from '@mui/material'
import ConfirmPersonForm from './confirm.guest.form/ConfirmPersonForm'
import ConfirmFamilyForm from './confirm.guest.form/ConfirmFamilyForm'

interface Props {
  guest: Guest
  onSuccess: (message: string) => void
}
const ConfirmGuestForm: FunctionComponent<Props> = ({ guest, onSuccess }) => {
  return (
    <Grid container spacing={1}>
      <Grid item sx={{ display: { xs: 'none', md: 'grid' } }} md={2}/>
      <Grid item xs={12} md={8}>
        <TextField value={guest.name} label="Invitación para" fullWidth/>
      </Grid>
      <Grid item sx={{ display: { xs: 'none', md: 'grid' } }} md={2}/>

      <Grid item sx={{ display: { xs: 'none', md: 'grid' } }} md={2}/>
      <Grid item xs={12} md={8}>
        {
          guest.type === GuestType.PERSON
            ? <ConfirmPersonForm guest={guest} onSuccess={onSuccess}/>
            : <ConfirmFamilyForm guest={guest} onSuccess={onSuccess}/>
        }
      </Grid>
      <Grid item sx={{ display: { xs: 'none', md: 'grid' } }} md={2}/>
    </Grid>

  )
}

export default ConfirmGuestForm
