import { Box } from '@mui/material'
import { type FunctionComponent } from 'react'

interface Props {
  backgroundImage: string
  opacity: number
  blur?: number
}
const Background: FunctionComponent<Props> = ({ backgroundImage, opacity, blur = 5 }) => {
  return (
    <>
      <Box sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'black',
        opacity,
        zIndex: -1
      }} />
      <Box sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: -2,
        backgroundImage,
        filter: `blur(${blur}px)`
      }} />
    </>
  )
}

export default Background
