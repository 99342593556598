import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { type FunctionComponent } from 'react'

const NumberBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  fontSize: '50px',
  fontWeight: 700,
  color: 'black',
  borderRadius: '15px',
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: '38px'
  }
}))

const TextBox = styled(Box)(({ theme }) => ({
  color: 'black',
  fontSize: '20px',
  [theme.breakpoints.down('md')]: {
    fontSize: '13px'
  }
}))

interface Props {
  value: number
  text: string
}
const DateNumberDisplay: FunctionComponent<Props> = ({ value, text }) => {
  return (
    <Grid item xs={3} md={2}>
      <NumberBox>
        {value}
        <TextBox>{text}</TextBox>
      </NumberBox>
    </Grid>
  )
}

export default DateNumberDisplay
