import { Container, Button, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Section from '../components/Section'
import { type FunctionComponent } from 'react'
import Background from '../components/Background'

const Location: FunctionComponent = () => {
  return (
    <Section>
      <Container
        sx={{
          mt: 6,
          mb: 14,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Background
          opacity={0.6}
          backgroundImage="url(https://cdn.pixabay.com/photo/2018/08/01/21/26/map-3578213_1280.jpg)" />
        <Typography align="center" variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
          Ubicación
        </Typography>
        <Typography align="center" variant="h5" sx={{ mt: 3 }}>
          Parroquia Santa Gema Galgani
        </Typography>
        <Typography align="center" variant="h6">
          17:30 Hrs.
        </Typography>
        <Typography align="center" variant="h6">
          Av. Suecia 3150, Ñuñoa
        </Typography>
        <Button
          sx={{
            borderColor: 'white',
            color: 'black',
            backgroundColor: 'white',
            width: { xs: '40%', md: '15%' },
            alignSelf: 'center'
          }}
          onClick={() => {
            window.open(
              'https://www.waze.com/es/live-map/directions/cl/region-metropolitana/nunoa/parroquia-santa-gema-galgani?to=place.ChIJjweHKZjPYpYRyUn3kDAFhfc',
              '_blank'
            )
          }}
          variant="outlined"
          size="large"
          startIcon={<LocationOnIcon />}>
          Navegar
        </Button>
        <Typography align="center" variant="h5" sx={{ mt: 5 }}>
          Terrazas de La Reina
        </Typography>
        <Typography align="center" variant="h6">
          19:30 Hrs.
        </Typography>
        <Typography align="center" variant="h6">
          Av. Alcalde Fernando Castillo Velasco 11095, La Reina
        </Typography>
        <Button
          sx={{
            borderColor: 'white',
            color: 'black',
            backgroundColor: 'white',
            width: { xs: '40%', md: '15%' },
            alignSelf: 'center'
          }}
          onClick={() => {
            window.open(
              'https://www.waze.com/es/live-map/directions/terrazas-de-la-reina-av.-alcalde-fernando-castillo-velasco-11095?to=place.w.189728185.1897150783.3877253',
              '_blank'
            )
          }}
          variant="contained"
          size="large"
          startIcon={<LocationOnIcon />}>
          Navegar
        </Button>
      </Container>
    </Section>
  )
}

export default Location
